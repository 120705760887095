import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { motion, useInView } from "framer-motion"
import {
    responsiveCss,
    responsiveSectionSpacing,
    responsiveSpacing,
    useMediaQuery,
} from "../../helpers/css"
import { springAnimations } from "../../constants/animation"
import { css } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { Heading } from "../typography/Heading"
import { Button } from "../buttons/Button"
import { CarouselBase, CarouselBasePosition, CarouselBaseRef } from "../base/CarouselBase"
import { CardsCarousel } from "../carousel/CardsCarousel"
import { pageSize } from "../../constants/sizes"

type CardsBlockProps<T> = {
    heading?: string
    showSlideButtons?: boolean
    cards: T[]
    renderCard: (card: T, index: number) => ReactNode
    enterAnimation?: boolean
}

export function CardsBlock<T>(props: CardsBlockProps<T>) {
    const containerRef = useRef<HTMLDivElement>(null)
    const cardContainerRef = useRef<HTMLDivElement>(null)
    const isInView = useInView(containerRef, { once: true, amount: 0.25 })
    const [position, setPosition] = useState<CarouselBasePosition>({
        atStart: true,
        atEnd: false,
    })
    const carouselBaseRef = useRef<CarouselBaseRef>(null)

    return (
        <div
            ref={containerRef}
            css={css(
                {
                    display: "flex",
                    flexDirection: "column",
                },
                responsiveSectionSpacing()
            )}>
            <Flex
                alignItems="center"
                justifyContent="space-between"
                css={css({ marginBottom: 16 }, responsiveCss("min", "md", { marginBottom: 24 }))}>
                <Heading level={2} padding={{ y: 8 }} truncate>
                    {props.heading}
                </Heading>
                {props.showSlideButtons && (
                    <Flex shrink={0} margin={{ left: 16 }}>
                        <Button
                            size="sm"
                            variant="secondary"
                            icon="chevronLeft"
                            margin={{ right: 4 }}
                            disabled={position.atStart}
                            onClick={() => carouselBaseRef.current?.prev()}
                        />
                        <Button
                            size="sm"
                            variant="secondary"
                            icon="chevronRight"
                            disabled={position.atEnd}
                            onClick={() => carouselBaseRef.current?.next()}
                        />
                    </Flex>
                )}
            </Flex>
            <div ref={cardContainerRef} css={responsiveSpacing("sm", "--gap")}>
                <CardsCarousel
                    ref={carouselBaseRef}
                    onPositionChange={(p) => {
                        if (p.atStart !== position.atStart || p.atEnd !== position.atEnd) {
                            setPosition(p)
                        }
                    }}
                    items={props.cards}
                    renderContainer={(children, scrollContainerRef) => (
                        <motion.div
                            ref={scrollContainerRef}
                            initial={{
                                transform: "translateX(200px)",
                                gap: "64px",
                                opacity: 0,
                            }}
                            animate={
                                (isInView || !props.enterAnimation) && {
                                    transform: "translateX(0px)",
                                    gap: "var(--gap)",
                                    opacity: 1,
                                }
                            }
                            transition={
                                props.enterAnimation ? springAnimations["600"] : { duration: 0 }
                            }
                            css={css(
                                {
                                    display: "grid",
                                    gridAutoColumns: "minmax(auto, 1fr)",
                                    gridAutoFlow: "column",
                                    overflowX: "scroll",
                                    scrollSnapType: "x mandatory",
                                    scrollbarWidth: "none",
                                    paddingLeft: pageSize.xs.paddingLeft,
                                    paddingRight: pageSize.xs.paddingRight,
                                    scrollPaddingLeft: pageSize.xs.paddingLeft,
                                    scrollPaddingRight: pageSize.xs.paddingRight,
                                },
                                css`
                                    &::-webkit-scrollbar {
                                        display: none;
                                    }
                                `,
                                css`
                                    @media (max-width: ${pageSize.xs.maxWidth}px) {
                                        margin-left: calc(50% - 50vw);
                                        width: 100vw;
                                    }
                                `,
                                responsiveCss("min", "md", {
                                    paddingLeft: pageSize.md.paddingLeft,
                                    paddingRight: pageSize.md.paddingRight,
                                    scrollPaddingLeft: pageSize.md.paddingLeft,
                                    scrollPaddingRight: pageSize.md.paddingRight,
                                }),
                                css`
                                    @media (min-width: ${pageSize.xs.maxWidth + 1}px) {
                                        padding-left: 0;
                                        padding-right: 0;
                                        scroll-padding-left: 0;
                                        scroll-padding-right: 0;
                                    }
                                `
                            )}>
                            {children}
                        </motion.div>
                    )}
                    renderItem={props.renderCard}
                />
            </div>
        </div>
    )
}
