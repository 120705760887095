import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Image, NonNegativeInteger, Uuid } from "../../../../reactor"
import { WebPage } from "../../../../packages/web/model/WebPage"
import { HighlightCarousel } from "../ui/components/carousel/HighlightCarousel"

/**
 * @icon ui-arrow-square-right
 */
function Carousel(section: {
    /**
     * The slides to show in the carousel.
     *
     * @expand
     */
    slides: {
        readonly id: Uuid<any>

        /**
         * @title
         */
        heading: Localized<string>

        text: Localized<string>
        /**
         * How to align the text.
         */
        textAlign: "left" | "center" | "right"

        /**
         * The color theme to use for the slide. Light or dark.
         */
        theme: "light" | "dark"

        /**
         * Call to action for this slide. Renders a button with a text that links to an URL.
         *
         * @label Call to action
         */
        cta?: {
            text: Localized<string>
            url: WebPage["id"]
        }

        /**
         * Image that is rendered as a background image. Either as a cover for slides with
         * text align center, or to the opposite side of the text for text align left or right.
         */
        image?: Image
    }[]

    /**
     * Automatically switch to the next slide after this time (in seconds). Clear value to
     * disable this feature.
     *
     * @default 5
     */
    timePerSlide?: NonNegativeInteger
}) {
    const localize = useLocalize()

    return (
        <HighlightCarousel
            autoSlide={typeof section.timePerSlide !== "undefined"}
            timePerSlide={section.timePerSlide ? section.timePerSlide.valueOf() * 1000 : undefined}
            slides={section.slides.map((s, i) => ({
                id: s.id.valueOf(),
                heading: localize(s.heading),
                text: localize(s.text),
                textAlign: s.textAlign,
                theme: s.theme,
                cta: s.cta
                    ? { text: localize(s.cta.text), url: `/${s.cta.url.valueOf()}` }
                    : undefined,
                image: s.image,
            }))}
        />
    )
}

Section(Carousel)
