import { css } from "../ui/helpers/css"
import { Section } from "../../../../packages/editing/Section"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { useFooter } from "../client"
import { Logo } from "../ui/components/visual/Logo"
import { responsiveCss } from "../ui/helpers/css"
import { Body } from "../ui/components/typography/Body"
import { sectionSpacing } from "../ui/constants/sizes"
import { openWaitingListSignupModal } from "./WaitingListSignupModal"

/**
 * Section displaying logo and some links on the bottom of the page.
 *
 * @icon ui-flex-align-bottom
 */
function Footer(section: {}) {
    const localize = useLocalize()
    const footer = useFooter()

    if (!footer.data) return <></>

    return (
        <div
            css={css(
                { paddingTop: sectionSpacing.xs },
                responsiveCss("min", "md", { paddingTop: sectionSpacing.md })
            )}>
            <Logo css={css({ width: "100%", height: "auto" })} />
            <div
                css={css(
                    { display: "flex", marginTop: 32, flexDirection: "column" },
                    responsiveCss("min", "md", {
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 24,
                    })
                )}>
                <div
                    css={css(
                        { display: "grid", gap: 16 },
                        responsiveCss("min", "md", { gridAutoFlow: "column", gap: 24 })
                    )}>
                    {footer.data.items.map((item) => (
                        <a
                            // href={item.url}
                            key={item.id.valueOf()}
                            onClick={() => openWaitingListSignupModal("footer")}
                            style={{ cursor: "pointer" }}>
                            <Body size="md">{localize(item.text)}</Body>
                        </a>
                    ))}
                </div>
                <div
                    css={css(
                        { opacity: 0.6, textAlign: "right", marginTop: 32 },
                        responsiveCss("min", "md", { marginTop: 0 })
                    )}>
                    <Body size="md">© 2024 Redoit · All rights reserved</Body>
                </div>
            </div>
        </div>
    )
}
Section(Footer)
