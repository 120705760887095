import { ReactNode } from "react"
import { css } from "../../helpers/css"
import { Color } from "../../constants/colors"
import { Text } from "../typography/Text"
import { responsiveCss } from "../../helpers/css"
import { Flex } from "../base/Flex"

export function LabelNotification(props: { color: Color; children: ReactNode }) {
    return (
        <Flex
            alignItems="center"
            backgroundColor={props.color}
            color="grayWhite"
            borderRadius="md"
            css={css(
                {
                    height: 30,
                    paddingLeft: 12,
                    paddingRight: 12,
                },
                responsiveCss("min", "lg", {
                    height: 32,
                    paddingLeft: 14,
                    paddingRight: 14,
                }),
                responsiveCss("min", "xl", {
                    height: 36 * 0.9,
                    paddingLeft: 16 * 0.9,
                    paddingRight: 16 * 0.9,
                })
            )}>
            <Text variant="label">{props.children}</Text>
        </Flex>
    )
}
