import { keyframes } from "@emotion/react"
import { css } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import { File, FileToUrl, Image, Url, Uuid } from "../../../../../../reactor"
import { server } from "../../../../../../server"
import { responsiveCss } from "../../helpers/css"
import { Box } from "../base/Box"
import { useEffect, useMemo, useRef, useState } from "react"

const defaultSpeed = 30

export function PressLogosCarousel(props: {
    /**
     * Carousel speed in pixels per second.
     */
    speed?: number
    logos: {
        readonly id: Uuid<"PressLogo">
        image: Image
        url?: Url
    }[]
}) {
    const ref = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState(0)

    useEffect(() => {
        setWidth(ref.current?.getBoundingClientRect().width || 0)
    }, [])

    const animationDuration = useMemo(
        () => `${((width ?? 0) / (props.speed ?? defaultSpeed)) * 1000}ms`,
        [props.speed, width]
    )

    return (
        <div
            style={{
                position: "relative",
                overflow: "hidden",
                flexWrap: "nowrap",
                display: "flex",
            }}
            css={containerCss}>
            <div ref={ref} style={{ animationDuration }} css={[responsiveGridCss, animationCss]}>
                {props.logos.map((l) => (
                    <Box
                        key={l.id.valueOf()}
                        css={responsiveItemCss}
                        {...(l.url ? { as: "a", href: l.url.valueOf(), target: "_blank" } : {})}>
                        <img src={FileToUrl(l.image as any as File)} style={{ opacity: 0.1 }} />
                    </Box>
                ))}
            </div>
            <div style={{ animationDuration }} css={[responsiveGridCss, animationCss]}>
                {props.logos.map((l) => (
                    <Box
                        key={l.id.valueOf()}
                        css={responsiveItemCss}
                        {...(l.url ? { as: "a", href: l.url.valueOf(), target: "_blank" } : {})}>
                        <img src={FileToUrl(l.image as any as File)} style={{ opacity: 0.1 }} />
                    </Box>
                ))}
            </div>
        </div>
    )
}

const marquee = keyframes({
    from: {
        transform: "translateX(-100%)",
    },
    to: {
        transform: "translateX(0%)",
    },
})

const animationCss = css({
    animationName: marquee,
})

const responsiveGridCss = css({
    display: "inline-flex",
    alignItems: "center",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
})
const containerCss = css(
    {
        maxHeight: 64,
        "&:hover div": {
            animationPlayState: "paused",
        },
        "&:before, &:after": {
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            width: 50,
            zIndex: 1,
            pointerEvents: "none",
        },
        "&:before": {
            left: 0,
            background: "linear-gradient(270deg, rgba(255, 255,255,0), rgba(255, 255,255,1))",
        },
        "&:after": {
            right: 0,
            background: "linear-gradient(90deg, rgba(255, 255,255,0), rgba(255, 255,255,1))",
        },
    },
    responsiveCss("min", "md", {
        "&:before, &:after": {
            width: 360,
            maxHeight: 80,
        },
    })
)

const responsiveItemCss = css(
    {
        display: "flex",
        justifyContent: "center",
        margin: "0 16px",
        minWidth: 200,
    },
    responsiveCss("min", "md", {
        margin: "0 50px",
        minWidth: 240,
    })
)

Component(PressLogosCarousel, {
    name: "PressLogosCarousel",
    gallery: {
        items: [
            {
                variants: [
                    {
                        props: {
                            logos: [
                                {
                                    id: Uuid<"PressLogo">(),
                                    image: `${server()}/static/redoit/press-logo-1.svg` as any as Image,
                                    url: Url("https://www.hollywoodreporter.com"),
                                },
                                {
                                    id: Uuid<"PressLogo">(),
                                    image: `${server()}/static/redoit/press-logo-2.svg` as any as Image,
                                },
                                {
                                    id: Uuid<"PressLogo">(),
                                    image: `${server()}/static/redoit/press-logo-3.svg` as any as Image,
                                },
                                {
                                    id: Uuid<"PressLogo">(),
                                    image: `${server()}/static/redoit/press-logo-4.svg` as any as Image,
                                },
                            ],
                        },
                    },
                ],
            },
        ],
    },
})
