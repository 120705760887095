import { ReactNode, useCallback, useEffect } from "react"
import { createPortal } from "react-dom"
import { css } from "../../helpers/css"
import { springAnimations } from "../../constants/animation"
import { Component } from "../../../../../../packages/editing/Component"
import { AnimatePresence, motion } from "framer-motion"
import { responsiveCss, scaleValue } from "../../helpers/css"
import { Icon, IconName } from "../visual/Icon"
import { Heading } from "../typography/Heading"
import { Flex } from "../base/Flex"
import { Button } from "../buttons/Button"
import { lipsum } from "../../helpers/lipsum"
import { Body } from "../typography/Body"
import { colors } from "../../constants/colors"
import { useLocalize } from "../../../../../../packages/localization/client-side/useLocalize"

export function Modal(props: {
    isOpen?: boolean
    onClose?: () => void
    onOpenAnimationEnd?: () => void
    onCloseAnimationEnd?: () => void
    header?: {
        title?: string
        icon?: IconName
        closeButton?: boolean
    }

    /**
     * @reflection any
     */
    children: ReactNode
}) {
    const localize = useLocalize()

    const handleKeydown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                props.onClose?.()
            }
        },
        [props]
    )

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (props.isOpen) {
                document.body.style.overflow = "hidden"
            } else {
                document.body.style.overflow = ""
            }
        }
    }, [props.isOpen])

    useEffect(() => {
        if (typeof window !== undefined) {
            window.addEventListener("keydown", handleKeydown)
            return () => {
                window.removeEventListener("keydown", handleKeydown)
            }
        }
    }, [handleKeydown])

    if (typeof window === "undefined") return <></>

    return createPortal(
        <AnimatePresence>
            {props.isOpen ? (
                <motion.div
                    key="backdrop"
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        zIndex: 100,
                        opacity: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.32)",
                    }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                />
            ) : null}
            {props.isOpen ? (
                <div
                    key="container"
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                    }}
                    css={containerCss}>
                    <Flex
                        key="modal"
                        motion={{
                            transition: springAnimations["200"],
                            initial: { transform: "translateY(50px)", scale: 0.75, opacity: 0 },
                            animate: { transform: "translateY(0px)", scale: 1, opacity: 1 },
                            exit: { opacity: 0 },
                        }}
                        direction="column"
                        backgroundColor="grayWhite"
                        borderRadius="lg"
                        elevation
                        css={modalCss}>
                        {props.header ? (
                            <Flex
                                justifyContent="space-between"
                                style={{
                                    paddingBottom: 24,
                                    marginBottom: 24,
                                    borderBottom: `1px solid ${colors.gray200}`,
                                }}>
                                <Flex alignItems="center">
                                    {props.header.icon ? (
                                        <Icon icon={props.header.icon} margin={{ right: 8 }} />
                                    ) : null}
                                    {props.header.title ? (
                                        <Heading level={2}>{props.header.title}</Heading>
                                    ) : null}
                                </Flex>
                                {props.header.closeButton ? (
                                    <Button
                                        icon="close"
                                        variant="secondary"
                                        size="sm"
                                        onClick={props.onClose}
                                        margin={{ left: scaleValue(40) }}
                                        aria-label={localize({
                                            no: "Lukk denne popupen",
                                            en: "Close this popup",
                                        })}
                                    />
                                ) : null}
                            </Flex>
                        ) : null}
                        <div style={{ overflowY: "auto", maxHeight: "100%", flex: "1 1 auto" }}>
                            {props.children}
                        </div>
                    </Flex>
                </div>
            ) : (
                <></>
            )}
        </AnimatePresence>,
        document.body
    )
}

const containerCss = css(
    {
        padding: 16,
    },
    responsiveCss("min", "md", {
        padding: "140px 96px",
    })
)

const modalCss = css(
    {
        backgroundColor: colors.grayWhite,
        maxHeight: "100%",
        padding: 16,
    },
    responsiveCss("min", "md", {
        maxWidth: 960,
        padding: 40,
    })
)

Component(Modal, {
    name: "Modal",
    gallery: {
        path: "Modal/Modal",
        items: [
            {
                variants: [
                    {
                        props: (state = { isOpen: true }, setState) => ({
                            isOpen: state.isOpen,
                            onClose: () => setState({ isOpen: false }),
                            header: {
                                title: "Trade-in",
                                icon: "handCoins" as IconName,
                                closeButton: true,
                            },
                            children: (
                                <div>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {lipsum(3)}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {lipsum(100)}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {" "}
                                        {lipsum(3)}{" "}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {" "}
                                        {lipsum(100)}{" "}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {" "}
                                        {lipsum(3)}{" "}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {" "}
                                        {lipsum(100)}{" "}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {lipsum(3)}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {lipsum(100)}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {lipsum(3)}
                                    </Heading>
                                    <Body size="md">{lipsum(100)} </Body>
                                </div>
                            ),
                        }),
                        render: (cmp, _, setState) => (
                            <div>
                                <button
                                    onClick={() => {
                                        setState({ isOpen: true })
                                    }}>
                                    Open modal
                                </button>
                                {cmp}
                            </div>
                        ),
                    },
                ],
            },
        ],
    },
})
