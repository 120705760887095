import { Interpolation } from "@emotion/react"
import { css } from "../../helpers/css"
import { TrustpilotStar } from "../visual/TrustpilotStars"
import { BoxProps } from "../base/Box"
import { colors } from "../../constants/colors"
import { responsiveHeadingSize } from "../../helpers/css"
import { xlScaleFactor } from "../../constants/sizes"

const style = {
    default: {
        color: colors.gray500,
        borderColor: colors.gray200,
    },
    hover: { borderColor: colors.gray400 },
    focus: { borderColor: colors.gray500 },
}

export function TrustpilotButton(
    props: { href?: string; score: number; onClick?: () => void } & Pick<BoxProps, "margin">
) {
    return (
        <button
            onClick={props.onClick}
            css={css(
                {
                    display: "flex",
                    alignItems: "center",
                    borderStyle: "solid",
                    borderWidth: 1,
                    height: 48 * xlScaleFactor,
                    borderRadius: 24,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingBottom: 1,
                    backgroundColor: colors.grayWhite,
                },
                responsiveHeadingSize("4"),
                style.default
            )}>
            {props.score.toString().replace(".", ",")}
            <TrustpilotStar css={css({ marginLeft: 6, marginRight: 6 })} />
            Trustpilot
        </button>
    )
}
