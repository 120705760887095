import { colors } from "../../constants/colors"

export function Divider({
    horizontal,
    weight = 1,
    spacing = 8,
}: {
    /**
     * Spacing to sibling elements.
     */
    spacing?: number

    /**
     * Specify a number if you need a heavier divider than 1px.
     */
    weight?: number
} & (
    | {
          /**
           * Display a horizontal divider.
           */
          horizontal: true
          vertical?: never
      }
    | {
          /**
           * Display a vertical divider.
           */
          vertical: true
          horizontal?: never
      }
)) {
    return (
        <div
            style={{
                [horizontal ? "height" : "width"]: weight,
                backgroundColor: colors.gray200,
                borderRadius: weight,
                margin: `${horizontal ? `${spacing}px 0` : `0 ${spacing}`}`,
            }}
        />
    )
}
