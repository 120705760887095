import { useCallback, MouseEvent, SVGProps } from "react"
import { css } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import { Box } from "../base/Box"
import { colors } from "../../constants/colors"

const boxStyle = css({
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 24,
    height: 24,
    width: 24,
    display: "flex",
})

export function RadioButton(props: {
    /**
     * Whether the checkbox is checked or not.
     */
    checked: boolean

    /**
     * Name for the radio button, relevant if part of a form.
     */
    name?: string

    /**
     * Whether this element is available for interaction or not.
     */
    disabled?: boolean

    /**
     * @reflection any
     */
    onClick?: (e: MouseEvent) => void
}) {
    const handleClick = useCallback(
        (e: MouseEvent) => {
            if (typeof props.onClick === "function") props.onClick(e)
        },
        [props.checked]
    )

    return (
        <Box
            onClick={handleClick}
            backgroundColor={
                props.disabled
                    ? props.checked
                        ? "gray200"
                        : "gray100"
                    : props.checked
                      ? "brand"
                      : "grayWhite"
            }
            borderColor={props.disabled ? "gray200" : props.checked ? "brand" : "gray200"}
            css={css(boxStyle, { cursor: props.disabled ? "default" : "pointer" })}>
            <input
                type="radio"
                disabled={props.disabled}
                style={{ opacity: 0, position: "absolute", cursor: "inherit" }}
            />
            {props.checked && (
                <div
                    css={css({
                        height: "100%",
                        width: "100%",
                        borderRadius: 24,
                        border: `2px solid ${colors.grayWhite}`,
                    })}
                />
            )}
        </Box>
    )
}

Component(RadioButton, {
    name: "RadioButton",
    gallery: {
        path: "Controllers/RadioButton",
        items: [
            {
                title: "Enabled",
                variants: [
                    {
                        label: "Interactive",
                        props: (state = { checked: false }, setState) => ({
                            checked: state.checked,
                            onClick: () => {
                                setState({ checked: !state.checked })
                            },
                        }),
                    },
                    { label: "Checked", props: { checked: true } },
                ],
            },
            {
                title: "Disabled",
                variants: [
                    { label: "Unchecked", props: { checked: false, disabled: true } },
                    { label: "Checked", props: { checked: true, disabled: true } },
                ],
            },
        ],
    },
})
