import { useCallback, useState } from "react"
import { css } from "../ui/helpers/css"
import { Markdown, Uuid } from "../../../../reactor"
import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { Notification } from "../ui/components/Notification"
import { EditableText } from "../../../../packages/editing/EditableText"
import { resetStyles, responsiveBodySize } from "../ui/helpers/css"
import { postNotificationsClosed, useNotificationsClosed } from "../client"

/**
 * @icon ui-announcement-03
 */
function FullNotification(section: {
    /**
     * Id of the section.

     * @hide
     */
    readonly id: Uuid<any>

    /**
     * The text to present in the notification.
     */
    text: Localized<Markdown>

    /**
     * Whether the user is allowed to close the notification
     */
    allowClose?: boolean
}) {
    const notificationsClosed = useNotificationsClosed()
    const [justClosed, setJustClosed] = useState(false)

    const handleClose = useCallback(async () => {
        void postNotificationsClosed({ id: section.id.valueOf() })
        setJustClosed(true)
    }, [section.id])

    return notificationsClosed.data?.includes(section.id.valueOf()) || justClosed ? (
        <></>
    ) : (
        <Notification full onClose={handleClose}>
            <EditableText
                isMarkdown
                obj={section}
                prop="text"
                css={css({
                    a: { textDecoration: "underline" },
                    p: [resetStyles("p"), responsiveBodySize("md")],
                })}
            />
        </Notification>
    )
}

Section(FullNotification)
