import { useCallback, useContext, useMemo } from "react"
import { Link } from "react-router-dom"
import { css } from "../ui/helpers/css"
import { useHeader } from "../client"
import { useDefaultLocale, useLocales } from "../../../../studio/client"
import { Section } from "../../../../packages/editing/Section"
import { LocaleKey } from "../../../../packages/localization/Locale"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { CurrentLocaleContext } from "../../../../packages/localization/client-side/CurrentLocaleContext"
import { colors } from "../ui/constants/colors"
import { Logo } from "../ui/components/visual/Logo"
import { Flex } from "../ui/components/base/Flex"
import { Icon, IconName } from "../ui/components/visual/Icon"
import { Button, responsiveButtonCss } from "../ui/components/buttons/Button"
import { TrustpilotButton } from "../ui/components/buttons/TrustpilotButton"
import { MenuItem } from "../../model/Header"
import {
    responsiveBorderRadius,
    responsiveCss,
    responsiveHeadingSize,
    scaleValue,
} from "../ui/helpers/css"
import { LocaleSelect } from "../ui/components/controllers/LocaleSelect"
import { NavigationModal } from "../ui/components/modal/NavigationModal"
import { Text } from "../ui/components/typography/Text"
import { openWaitingListSignupModal } from "./WaitingListSignupModal"
import { WebPageContext } from "../../../../packages/web/components/WebPage"
import { Uuid } from "../../../../reactor"

/**
 * The header for the site. Renders the first item separate from the rest, as they are
 * to be rendered in a hamburger popup menu on mobile, while the first item should be visible
 * outside of the popup menu.
 *
 * @icon ui-menu-01
 */
function Header(section: { readonly id: Uuid<"Section">; menuItems?: MenuItem[] }) {
    const webPageContext = useContext(WebPageContext)
    const locales = useLocales()
    const localize = useLocalize()
    const header = useHeader(webPageContext.id, section.id as any as Uuid<"Section">)
    const { locale: currentLocale, setLocale } = useContext(CurrentLocaleContext)
    const defaultLocale = useDefaultLocale().data?.defaultLocale
    const menuItems = useMemo(
        () =>
            header.data?.items.map((item) => {
                const localePath = currentLocale === defaultLocale ? "" : `/${currentLocale}`
                const path = item.slug ? `${localePath}/${localize(item.slug)}` : "/"
                const fragment = item.section ? `#${item.section}` : ""
                const href = item.url && !item.slug ? item.url : `${path}${fragment}`

                // During pre launch period, fragments and external links (items with url property)
                // should work as intended, while other links should trigger open waiting list
                // modal.
                return {
                    id: item.id.valueOf(),
                    href: (item.slug || item.section || item.url) && href ? href : undefined,
                    text: localize(item.text),
                    icon: item.icon,
                    onClick:
                        (item.slug || item.section || item.url) && href
                            ? undefined
                            : () => openWaitingListSignupModal("menu"),
                }
            }) || [],
        [header.data?.items, currentLocale, defaultLocale, localize]
    )

    const handleLocaleChange = useCallback(
        (l: LocaleKey) => {
            if (setLocale) {
                setLocale(l)
            }
        },
        [setLocale]
    )

    if (header.loading || !header.data) {
        return <></>
    }

    return (
        <Flex as="nav" alignItems="center" css={css({ height: scaleValue(100) })}>
            <Flex alignItems="center" style={{ width: "100%" }}>
                <Link to={`/${currentLocale === defaultLocale ? "" : currentLocale}`}>
                    <Logo
                        css={css({ width: scaleValue(114), height: "auto", marginRight: scale16 })}
                    />
                </Link>
                {header.data.trustpilot ? (
                    <div css={css(responsiveCss("max", "sm", { display: "none" }))}>
                        <TrustpilotButton
                            margin={{ right: scale16 }}
                            href={header.data.trustpilot.url}
                            score={header.data.trustpilot.score.valueOf()}
                        />
                    </div>
                ) : null}
                <Flex
                    css={css(
                        {
                            alignItems: "flex-end",
                            "> div": { display: "none" },
                            "> div:first-of-type": {
                                display: "flex",
                            },
                        },
                        responsiveCss("max", "lg", {
                            marginLeft: "auto",
                        }),
                        responsiveCss("min", "xl", {
                            alignItems: "flex-start",
                            "> div": { display: "flex" },
                        })
                    )}>
                    {!!menuItems[0] && <MenuItem {...menuItems[0]} />}
                    {menuItems.slice(1, menuItems.length).map((item) => (
                        <MenuItem key={item.id} {...item} />
                    ))}
                </Flex>
            </Flex>
            <div css={css(responsiveCss("max", "lg", { display: "none" }))}>
                {header.data.login ? (
                    <Flex>
                        <LocaleSelect
                            locales={locales.data || []}
                            currentLocale={currentLocale}
                            onLocaleChange={handleLocaleChange}
                        />
                        <Button
                            margin={{ left: 8 }}
                            variant="dark"
                            size="sm"
                            // href={header.data.login.url.valueOf()}
                            onClick={() => openWaitingListSignupModal("header")}>
                            <Text variant="heading" level="4">
                                {localize(header.data.login.text)}
                            </Text>
                        </Button>
                    </Flex>
                ) : null}
            </div>
            <div css={css({ marginLeft: 6 }, responsiveCss("min", "xl", { display: "none" }))}>
                <NavigationModal
                    items={menuItems}
                    login={
                        header.data.login
                            ? {
                                  text: localize(header.data.login?.text),
                                  url: header.data.login.url,
                              }
                            : undefined
                    }
                    trustpilot={
                        header.data.trustpilot
                            ? {
                                  score: header.data.trustpilot.score,
                                  text: localize(header.data.trustpilot.text),
                                  url: header.data.trustpilot.url,
                              }
                            : undefined
                    }
                    locales={locales.data || []}
                    currentLocale={currentLocale}
                    onLocaleChange={handleLocaleChange}
                />
            </div>
        </Flex>
    )
}

function MenuItem(props: { href?: string; text: string; icon?: IconName; onClick?: () => void }) {
    return (
        <Flex css={css(spacer)}>
            <Flex
                as="a"
                href={props.href}
                padding={{ y: scaleValue(12), x: scale16 }}
                alignItems="center"
                css={menuItemCss}
                onClick={props.onClick}>
                {props.icon && <Icon icon={props.icon} margin={{ right: scaleValue(8) }} />}
                {props.text}
            </Flex>
        </Flex>
    )
}

const menuItemCss = css([
    {
        cursor: "pointer",
        height: scaleValue(48),
        textDecoration: "none",
        whiteSpace: "nowrap",
    },
    responsiveHeadingSize("4"),
    responsiveCss(
        "max",
        "lg",
        css(responsiveButtonCss("secondary", "sm", {}), responsiveBorderRadius("lg"))
    ),
])

const scale16 = scaleValue(16)

const spacer = css({
    alignItems: "center",
    ":before": {
        content: '""',
        width: 1,
        height: 20,
        backgroundColor: colors.gray200,
        marginLeft: scale16,
        marginRight: scale16,
    },
    ":first-of-type:before": {
        display: "none",
    },
})

Section("Header", Header)
