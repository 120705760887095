import { css } from "../../helpers/css"
import { File, FileToUrl, Image } from "../../../../../../reactor"
import { Flex } from "../base/Flex"
import { Box } from "../base/Box"
import { Component } from "../../../../../../packages/editing/Component"
import { responsiveCss } from "../../helpers/css"
import { Heading } from "../typography/Heading"
import { Button } from "../buttons/Button"
import { server } from "../../../../../../server"
import { openWaitingListSignupModal } from "../../../sections/WaitingListSignupModal"

export function SeeAllCard(props: {
    heading: string
    cta: { text: string; url: string }
    image: Image
}) {
    return (
        <Flex
            direction="column"
            justifyContent="space-between"
            borderRadius="md"
            backgroundColor="_productsSeeAllBg"
            style={{ position: "relative", height: "100%", overflow: "clip", flexGrow: 0 }}
            onClick={() => openWaitingListSignupModal("see-all-card")}
            {...(props.cta
                ? {
                      as: "a",
                      //   href: props.cta.url,
                      "aria-label": props.cta.text,
                  }
                : {})}>
            <Box
                css={css({ padding: 32 }, responsiveCss("min", "md", { padding: 64 }))}
                style={{ zIndex: 1 }}>
                <Heading level={2} margin={{ bottom: 16 }}>
                    {props.heading}
                </Heading>
                <Button variant="dark">{props.cta.text}</Button>
            </Box>
            <div
                style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    overflow: "hidden",
                    backgroundImage: `url(${FileToUrl(props.image as any as File)})`,
                    backgroundPosition: "bottom",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                }}
                css={css(
                    { marginLeft: 32, marginRight: 32 },
                    responsiveCss("min", "md", { marginLeft: 64, marginRight: 64 })
                )}
            />
        </Flex>
    )
}

Component(SeeAllCard, {
    name: "SeeAllCard",
    gallery: {
        path: "Cards/SeeAllCard",
        items: [
            {
                variants: [
                    {
                        props: {
                            heading: "Se hele vårt utvalg",
                            cta: { text: "Vis alt (14)", url: "" },
                            image: `${server()}/static/redoit/see-all-card.png` as any as Image,
                        },
                    },
                ],
            },
        ],
    },
})
