import { Component } from "../../../../../../packages/editing/Component"
import { resetStyles } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { Body } from "../typography/Body"
import { Heading } from "../typography/Heading"
import { Icon } from "../visual/Icon"

export function BoxSelection(props: {
    primary?: string
    secondary?: string
    text?: string
    list?: string[]
    link?: {
        text: string
        url: string
    }
    selected?: boolean
}) {
    return (
        <Flex
            backgroundColor={props.selected ? "brandLight" : "grayWhite"}
            borderColor={props.selected ? "brand" : "gray200"}
            borderRadius="md"
            style={{ padding: 16 }}>
            <div style={{ display: "grid", gap: 8, width: "100%" }}>
                {typeof props.secondary !== "undefined" && (
                    <Heading level={4} color="brand">
                        {props.secondary}
                    </Heading>
                )}
                {typeof props.primary !== "undefined" && (
                    <Heading level={3}>{props.primary}</Heading>
                )}
                {typeof props.text !== "undefined" && <Body size="sm">{props.text}</Body>}
                {typeof props.list !== "undefined" ? (
                    <ul css={resetStyles("ul")}>
                        {props.list.map((item) => (
                            <li key={item} style={{ display: "flex", alignItems: "center" }}>
                                <Icon icon="check" color="brand" style={{ marginRight: 4 }} />
                                <Body size="sm" color="gray400">
                                    {item}
                                </Body>
                            </li>
                        ))}
                    </ul>
                ) : null}
                {typeof props.link !== "undefined" && (
                    <a href={props.link.url}>
                        <Body size="sm" color="gray400" style={{ textAlign: "right" }} underline>
                            {props.link.text}
                        </Body>
                    </a>
                )}
            </div>
        </Flex>
    )
}

Component(BoxSelection, {
    name: "BoxSelection",
    gallery: {
        path: "Controllers/BoxSelection",
        items: [
            {
                title: "Selected",
                variants: [
                    {
                        props: {
                            primary: "Mobilforsikring fra Gjensidige",
                            secondary: "50 kr/md",
                            list: [
                                "Erstatning ved tyveri/tap mot egenandel.",
                                "Send mobilen inn gratis.",
                                "Motta direkte betaling til din konto.",
                            ],
                            link: { text: "Vilkår og betingelser", url: "#" },
                            selected: true,
                        },
                    },
                ],
            },
            {
                title: "Not selected",
                variants: [
                    {
                        props: {
                            primary: "Ingen forsikringsdekning",
                            text: "Du er selv ansvarlig for verdien av mobilen, som er 10 000kr, hvis den blir skaded, mistet eller stjålet.",
                        },
                    },
                ],
            },
        ],
    },
})
