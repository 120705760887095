import { useState, useCallback } from "react"
import { useSearchParams } from "react-router-dom"
import { Markdown, Uuid } from "../../../../reactor"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { LocaleKey } from "../../../../packages/localization/Locale"
import { MarkdownView } from "../../../../packages/markdown-edit/MarkdownView"
import { postNewsletterUnsubscribe, useNewsletterUnsubscribePage } from "../client"
import { Flex } from "../ui/components/base/Flex"
import { Logo } from "../ui/components/visual/Logo"
import { Heading } from "../ui/components/typography/Heading"
import { WarningMessage } from "../ui/components/inputs/WarningMessage"
import { Button } from "../ui/components/buttons/Button"
import { Localized } from "../../../../packages/localization/Localized"
import { css } from "@emotion/react"
import { scaleValue } from "../ui/helpers/css"

export function NewsletterUnsubscribePage(props: { locale: LocaleKey }) {
    const l = useLocalize()
    const page = useNewsletterUnsubscribePage()
    const [searchParams] = useSearchParams()
    const [errorMessage, setErrorMessage] = useState<Localized<Markdown> | undefined>()
    const [success, setSuccess] = useState(false)
    const id = searchParams.get("id")
    const { locale } = props
    const localize = (val: Localized<string | Markdown>) => l(val, locale)

    const handleUnsubscribeClick = useCallback(async () => {
        if (id && page.data) {
            try {
                await postNewsletterUnsubscribe({ id: id as any as Uuid<"WaitingListSignup"> })
                setSuccess(true)
            } catch (err) {
                setErrorMessage(page.data.errorMessage)
            }
        }
    }, [id, page.data])

    if (!page.data) return <></>

    return (
        <Flex direction="column" padding={{ x: 24 }}>
            <Flex alignItems="center" style={{ height: scaleValue(100) }}>
                <Logo
                    css={css({
                        width: scaleValue(114),
                        height: "auto",
                        marginRight: scaleValue(16),
                    })}
                />
            </Flex>
            <div>
                <Heading level={2} margin={{ bottom: "sm" }}>
                    {localize(page.data.heading)}
                </Heading>
                {!id ? (
                    <MarkdownView value={localize(page.data.missingParameterMessage)} />
                ) : success ? (
                    <MarkdownView value={localize(page.data.successMessage)} />
                ) : (
                    <>
                        <MarkdownView value={localize(page.data.text)} />
                        {errorMessage ? (
                            <WarningMessage>
                                <MarkdownView value={localize(errorMessage)} />
                            </WarningMessage>
                        ) : null}
                        <Button variant="dark" onClick={handleUnsubscribeClick}>
                            {localize(page.data.buttonText)}
                        </Button>
                    </>
                )}
            </div>
        </Flex>
    )
}
