import { keyframes } from "@emotion/react"
import { css } from "../../helpers/css"
import { Color, colors } from "../../constants/colors"

export function MenuCloseIcon(props: {
    state: "menu" | "close"
    duration?: string
    color?: Color
}) {
    const fillColor = props.color ? colors[props.color] : "currentColor"
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={css(
                svgCss,
                props.state === "close"
                    ? css`
                          > rect {
                              transform-origin: center;
                              animation: ${animation} ${props.duration || "400ms"} normal forwards;
                          }
                      `
                    : {}
            )}>
            <rect className="top" x="4" y="8" width="16" height="2" fill={fillColor} />
            <rect className="bottom" x="4" y="14" width="16" height="2" fill={fillColor} />
        </svg>
    )
}

const animation = keyframes`
    0% {
        transform: rotate(0deg) translate(0px);
    }
    40% {
        transform: 
            rotate(0deg)
            translate(0, calc(var(--icon-translation-size) * var(--factor) * 1px));
    }
    100% {
        transform: 
            rotate(calc(45deg * var(--factor)))
            translate(0, calc(var(--icon-translation-size) * var(--factor) * 1px));
    }
`

const svgCss = css`
    --icon-size: 9;
    --icon-bar-size: 3;
    --icon-translation-size: calc((var(--icon-size) - var(--icon-bar-size)) / 2);

    .top {
        --factor: 1;
    }

    .bottom {
        --factor: -1;
    }
`
