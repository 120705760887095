import { Outlet, Route } from "react-router-dom"
import { Global, css } from "@emotion/react"
import { Lazy } from "../packages/code-splitting/Lazy"

export function Gallery() {
    return (
        <>
            <Route
                path="/gallery"
                element={
                    <>
                        <Global
                            styles={css`
                                @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

                                *,
                                *:before,
                                *:after {
                                    box-sizing: border-box;
                                }

                                html,
                                body {
                                    font-family: "Inter", sans-serif;
                                    background-color: "#f4f5f7";
                                }
                            `}
                        />
                        <Outlet />
                    </>
                }>
                <Route index element={<IndexLazy />} />
                <Route path="components/*" element={<ComponentLazy />} />
            </Route>
        </>
    )
}

function IndexLazy() {
    return <Lazy target={async () => (await import("./pages/IndexPage")).Index} />
}

function ComponentLazy() {
    return <Lazy target={async () => (await import("./pages/ComponentPage")).ComponentPage} />
}
