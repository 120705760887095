import { ReactNode, useCallback, useRef } from "react"
import { Link } from "react-router-dom"
import { SerializedStyles } from "@emotion/react"
import { css } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import {
    ResponsiveVariant,
    responsiveVariantsCss,
    variantCss,
    VariantStyles,
    responsiveHeadingSize,
} from "../../helpers/css"
import { colors } from "../../constants/colors"
import { Icon, IconName } from "../visual/Icon"
import { buttonIconOnlySizes, ButtonSize, buttonSizes } from "../../constants/sizes"
import { Spacing, spacingToCss } from "../base/Box"

const buttonVariants = ["primary", "secondary", "dark", "outlined"] as const
type ButtonVariant = (typeof buttonVariants)[number]

const variants: VariantStyles<ButtonVariant> = {
    primary: {
        color: colors.grayWhite,
        background: colors.brand,
        "&:hover:not([disabled])": {
            color: colors.grayWhite,
            backgroundColor: colors.brandDark,
        },
        "&:focus": { color: colors.gray500, backgroundColor: colors.brandLight },
    },
    secondary: {
        color: colors.gray500,
        backgroundColor: colors.gray100,
        "&:hover:not([disabled])": {
            backgroundColor: colors.gray200,
        },
        "&:focus": {
            color: colors.gray500,
            borderWidth: 2,
            borderColor: colors.gray500,
        },
    },
    dark: {
        color: colors.grayWhite,
        backgroundColor: colors.gray500,
        "&:hover:not([disabled])": { color: colors.grayWhite, backgroundColor: colors.gray400 },
        "&:focus": { color: colors.gray500, backgroundColor: colors.gray200 },
    },
    outlined: {
        color: colors.gray500,
        borderColor: colors.gray200,
        "&:hover:not([disabled])": { borderColor: colors.gray400 },
        "&:focus": { borderColor: colors.gray500 },
    },
}

type ButtonProps = {
    variant?: ResponsiveVariant<ButtonVariant>

    /**
     * Whether the button is disabled or not.
     */
    disabled?: boolean

    /**
     * Show an icon from the design system to the left of the button text.
     */
    icon?: IconName

    /**
     * @default "md"
     */
    size?: ResponsiveVariant<ButtonSize>

    /**
     * Click handler for the button.
     */
    onClick?: () => void

    /**
     * A URL to navigate to when the button is pressed. Passing a URL param renders the button as
     * an anchor element.
     */
    href?: string

    /**
     * Add spacing to other elements.
     */
    margin?: Spacing

    /**
     * Set to true if button should take up all width of parent element.
     */
    fullwidth?: boolean

    /**
     * @reflection any
     */
    children?: ReactNode

    /**
     * Only supported for gallery to add additional styles to present states like hover and focus
     * without actually hovering or focusing. Looking for other way to achieve this, so support
     * for this prop can be dropped at any time.
     *
     * @deprecated
     */
    className?: string
}

export function Button(props: ButtonProps) {
    const {
        variant = "primary",
        icon,
        size = "md",
        onClick,
        href,
        margin,
        fullwidth,
        children,
        className,
        ...rest
    } = props

    const styles = css([
        {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.5s",
            borderStyle: "solid",
            borderWidth: size === "md" ? 2 : 1,
            borderColor: "transparent",
            whiteSpace: "nowrap",
            width: fullwidth ? "100%" : "auto",
            borderRadius: "100vw",
        },
        responsiveButtonCss(variant, size, props),
        props.disabled && {
            backgroundColor: colors.gray100,
            color: colors.gray300,
            cursor: "default",
        },
        margin && spacingToCss("margin", margin),
        responsiveHeadingSize("4"),
    ])

    const buttonRef = useRef<HTMLButtonElement>(null)
    const anchorRef = useRef<HTMLAnchorElement>(null)

    const handleClick = useCallback(() => {
        onClick?.()

        // Prevent buttons from being stuck in active state after click is handled. Needed two
        // refs due to type errors when trying to use the same for button and anchor.
        buttonRef.current?.blur()
        anchorRef.current?.blur()
    }, [onClick])

    return href ? (
        <Link
            ref={anchorRef}
            to={href}
            css={styles}
            className={className ? className : ""}
            onClick={handleClick}>
            {children}
            {icon && <Icon margin={{ left: 8 }} icon={icon} />}
        </Link>
    ) : (
        <button
            ref={buttonRef}
            disabled={props.disabled}
            onClick={handleClick}
            css={styles}
            className={className ? className : ""}
            {...rest}>
            {children}
            {icon && <Icon margin={children ? { left: 8 } : 0} icon={icon} />}
        </button>
    )
}

Component(Button, {
    name: "Button",
    gallery: {
        about: "Buttons communicate actions that users can take.",
        path: "Button",
        items: [
            ...["Primary", "Secondary", "Dark"].map((item) => ({
                title: `${item} button`,
                variants: [
                    {
                        label: "Default",
                        props: {
                            variant: item.toLowerCase() as ButtonVariant,
                            children: "Kjøp nå",
                            icon: "arrowRight" as IconName,
                        },
                    },
                    {
                        label: "Inactive",
                        props: {
                            variant: item.toLowerCase() as ButtonVariant,
                            children: "Ipsum",
                            disabled: true,
                        },
                    },
                    {
                        label: "Hover",
                        props: { variant: item.toLowerCase() as ButtonVariant, children: "Dolor" },
                        pseudo: ":hover" as const,
                    },
                    {
                        label: "Focus",
                        props: {
                            variant: item.toLowerCase() as ButtonVariant,
                            children: "Click me",
                        },
                        pseudo: ":focus" as const,
                    },
                ],
            })),

            {
                title: "Icon only",
                variants: [
                    {
                        props: { icon: "plus" },
                    },
                ],
            },
            {
                title: "Small",
                variants: [
                    {
                        props: {
                            children: "Kjøp nå",
                            icon: "arrowRight" as IconName,
                            size: "sm" as ResponsiveVariant<ButtonSize>,
                        },
                    },
                ],
            },
            {
                title: "Media Query Responsive Variant",
                variants: [
                    {
                        props: {
                            variant: [
                                "dark",
                                ["min", "lg", "primary"],
                            ] as ResponsiveVariant<ButtonVariant>,
                            children: "Kjøp nå",
                            icon: "arrowRight" as IconName,
                            size: [
                                "sm",
                                ["min", "md", "md"],
                                ["min", "xl", "md"],
                            ] as ResponsiveVariant<ButtonSize>,
                        },
                    },
                ],
            },
        ],
    },
})

export function responsiveButtonCss(
    variant: ResponsiveVariant<ButtonVariant>,
    size: ResponsiveVariant<ButtonSize>,
    props?: ButtonProps
): SerializedStyles {
    const sizes = props?.icon && !props?.children ? buttonIconOnlySizes : buttonSizes
    return css([variantCss(variants, variant), responsiveVariantsCss(sizes, size)])
}
