import { ComponentPropsWithRef, ForwardedRef, ReactNode, forwardRef } from "react"
import { motion as Motion } from "framer-motion"
import { SerializedStyles, jsx } from "@emotion/react"
import { BorderRadiusVariant, isSpacingSize, SpacingSize } from "../../constants/sizes"
import { Color, colors } from "../../constants/colors"
import {
    ResponsiveStyleValue,
    ResponsiveVariant,
    css,
    responsiveBorderRadius,
    responsiveBoxShadow,
    responsivePropCss,
    responsiveSpacing,
    responsiveValueCss,
} from "../../helpers/css"
import { AnimationProps } from "framer-motion"
import { boxShadow } from "../../constants/shadow"

export type BoxProps<T extends keyof JSX.IntrinsicElements = "div"> = {
    /**
     * Specify which HTML element the Box should be rendered as.
     * By default it is rendered as a div.
     */
    as?: T

    /**
     * Specify padding.
     */
    padding?: Spacing

    /**
     * Specify margin.
     */
    margin?: Spacing

    /**
     * Add framer-motion animations. Any animation props that can be added as a prop
     * to the motion component can be added here.
     */
    motion?: AnimationProps

    /**
     * Specify a border radius. The border radius is specified in the relative
     * BorderRadiusVariant unit.
     */
    borderRadius?: BorderRadiusVariant

    /**
     * Specify a border color from the color palette. This will automatically add a border
     * with style solid and width 1. Use props `borderWidth` and `borderStyle`, or `css`
     * to override.
     */
    borderColor?: ResponsiveVariant<Color>

    /**
     * Specify border width.
     */
    borderWidth?: ResponsiveStyleValue

    /**
     * Specify a text color from the color palette.
     */
    color?: Color

    /**
     * Specify a text color from the color palette.
     */
    backgroundColor?: ResponsiveVariant<Color>

    /**
     * Elevate the box by applying a box-shadow.
     */
    elevation?: boolean

    /**
     * Any additional CSS to apply.
     */
    css?: SerializedStyles

    /**
     * @reflection any
     */
    children?: ReactNode
} & ComponentPropsWithRef<T>

/**
 * A generic Box component for a convenient way to render a Box with rules from the design system
 * applied, like colors and responsive border radius.
 */
export const Box = forwardRef(function Box<T extends keyof JSX.IntrinsicElements = "div">(
    {
        motion,
        backgroundColor,
        borderRadius,
        borderColor,
        borderWidth,
        color,
        elevation,
        padding,
        margin,
        as,
        children,
        ...rest
    }: BoxProps<T>,
    ref: ForwardedRef<HTMLElement>
) {
    const props = {
        style: rest.style,
        css: css(
            color && {
                color: colors[color],
            },
            backgroundColor && responsivePropCss(colors, "backgroundColor", backgroundColor),
            borderColor && {
                borderStyle: "solid",
                borderWidth: 1,
            },
            borderWidth && responsiveValueCss("borderWidth", borderWidth),
            borderColor && responsivePropCss(colors, "borderColor", borderColor),
            borderRadius && responsiveBorderRadius(borderRadius),
            padding && spacingToCss("padding", padding),
            margin && spacingToCss("margin", margin),
            elevation && responsiveBoxShadow(),
            rest.css
        ),
    }

    if (typeof motion !== "undefined") {
        return jsx(
            typeof motion !== "undefined" ? Motion[(as || "div") as "div"] : as || "div",
            {
                // The typing is starting to get complicated and struggeled with motion not
                // accepting the rest spread and the forwarded  ref. Cast rest as any fixes it,
                // and I don't think it should be a problem.
                ...(rest as any),
                ref,
                ...motion,
                ...props,
            },
            children
        )
    }
    return jsx(
        as || "div",
        {
            ...rest,
            ...props,
            ref,
        },
        children
    )
})

type Axis = "x" | "y"
type Side = "top" | "right" | "bottom" | "left"

/**
 * Specify spacing by either a number for same spacing in all directions, in x/y direction,
 * or specific spacing for top/right/bottom/left.
 */
export type Spacing =
    | number
    | string
    | SpacingSize
    | Partial<Record<Axis | Side, number | string | SpacingSize>>

function axisOrSideToProps(axisOrSide: Axis | Side, type: string): string | string[] {
    const axis = {
        x: [`${type}Left`, `${type}Right`],
        y: [`${type}Top`, `${type}Bottom`],
        top: `${type}Top`,
        bottom: `${type}Bottom`,
        left: `${type}Left`,
        right: `${type}Right`,
    }
    return axis[axisOrSide]
}

/**
 * Helper function to convert a Spacing prop to CSS.
 *
 * @param type The type of spacing to return rules for, either margin or padding.
 * @param spacing A valid spacing object or single value. Supports both pixels and SpacingSize.
 *
 * @returns An Emotion SerializedStyles object
 */
export function spacingToCss(type: "margin" | "padding", spacing: Spacing) {
    function spacingVal(axisOrSide: Axis | Side, val: number | string) {
        if (isSpacingSize(val)) return responsiveSpacing(val, axisOrSideToProps(axisOrSide, type))
    }
    return css([
        typeof spacing === "number" || typeof spacing === "string"
            ? { [type]: spacing }
            : [
                  spacing.x &&
                      (spacingVal("x", spacing.x) ?? {
                          [`${type}Left`]: spacing.x,
                          [`${type}Right`]: spacing.x,
                      }),
                  spacing.y &&
                      (spacingVal("y", spacing.y) ?? {
                          [`${type}Top`]: spacing.y,
                          [`${type}Bottom`]: spacing.y,
                      }),
                  spacing.top &&
                      (spacingVal("top", spacing.top) ?? {
                          [`${type}Top`]: spacing.top,
                      }),
                  spacing.right &&
                      (spacingVal("right", spacing.right) ?? { [`${type}Right`]: spacing.right }),
                  spacing.bottom &&
                      (spacingVal("bottom", spacing.bottom) ?? {
                          [`${type}Bottom`]: spacing.bottom,
                      }),
                  spacing.left &&
                      (spacingVal("left", spacing.left) ?? { [`${type}Left`]: spacing.left }),
              ],
    ])
}
