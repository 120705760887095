import { Section } from "../../../../packages/editing/Section"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { Image, ImageToCSS } from "../../../../reactor"

import { Flex } from "../ui/components/base/Flex"
import { Button } from "../ui/components/buttons/Button"
import { Heading } from "../ui/components/typography/Heading"
import { Color } from "../ui/constants/colors"
import { Text } from "../ui/components/typography/Text"
import { openWaitingListSignupModal } from "./WaitingListSignupModal"
import { responsiveSectionSpacing, scaleValue } from "../ui/helpers/css"

function WaitingListTrigger(section: {
    heading?: Localized<string>
    text?: Localized<string>
    /**
     * @default gray500
     */
    textColor: Color
    backgroundColor?: Color
    backgroundImage?: Image
    ctaText: Localized<string>
    source?: string
}) {
    const localize = useLocalize()
    return (
        <div css={responsiveSectionSpacing()}>
            <Flex
                alignItems="center"
                justifyContent="center"
                borderRadius="lg"
                direction="column"
                padding={24}
                style={{
                    textAlign: "center",
                    height: 300,
                    ...(section.backgroundImage
                        ? {
                              backgroundImage: ImageToCSS(section.backgroundImage),
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                          }
                        : {}),
                }}
                backgroundColor={section.backgroundColor}>
                {section.heading ? (
                    <Heading
                        level={1}
                        margin={{ bottom: scaleValue(24) }}
                        color={section.textColor}>
                        {localize(section.heading)}
                    </Heading>
                ) : null}
                {section.text ? (
                    <div style={{ marginBottom: scaleValue(24) }}>
                        <Text variant="body" size="lg" color={section.textColor}>
                            {localize(section.text)}
                        </Text>
                    </div>
                ) : null}
                <Button
                    variant="dark"
                    size="md"
                    onClick={() =>
                        openWaitingListSignupModal(section.source ?? "waiting-list-trigger")
                    }>
                    {localize(section.ctaText)}
                </Button>
            </Flex>
        </div>
    )
}

Section(WaitingListTrigger)
